// extracted by mini-css-extract-plugin
export var note = "styles-module--note--1tTGA";
export var page = "styles-module--page--1bzsQ";
export var page__sen = "styles-module--page__sen--UewjQ";
export var comment = "styles-module--comment--IkAAV";
export var userComment = "styles-module--userComment--23041";
export var userComment__date = "styles-module--userComment__date--zMo63";
export var userComment__text = "styles-module--userComment__text--1BFWI";
export var userComment__name = "styles-module--userComment__name--3-mWK";
export var channelComment = "styles-module--channelComment--3hErI";
export var channelComment__text = "styles-module--channelComment__text--1iC2N";
export var videoThumbnail = "styles-module--videoThumbnail--13fTn";
export var videoThumbnail__tape = "styles-module--videoThumbnail__tape--2D_jm";
export var videoThumbnail__image = "styles-module--videoThumbnail__image--11GOU";